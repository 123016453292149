import { useTranslation } from "react-i18next";
import { cn } from "~/util/cn";

export interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  inStock: boolean;
  leadTime?: string;
}

const Availability: React.FC<Props> = ({
  inStock,
  leadTime,
  className,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <div {...props} className={cn("flex items-center font-light", className)}>
      <div
        className={cn(
          `mr-2 h-[0.6rem] w-[0.6rem] rounded-full`,
          inStock ? "bg-green-500" : "bg-red-500",
        )}
      />
      {inStock ? t("availability.inStock") : t("availability.outOfStock")}
      {leadTime && (
        <>
          {", "}
          {t("availability.leadTime", { leadTime })}
        </>
      )}
    </div>
  );
};
export default Availability;
