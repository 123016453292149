import { cn } from "~/util/cn";
import Price from "./Price";

export interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  price: number;
  rrp: number;
}

const PriceRRP: React.FC<Props> = ({ price, rrp, className, ...props }) => {
  if (price === rrp) return null;
  const reducedBy = Math.floor(((rrp - price) / rrp) * 100);
  return (
    <div {...props} className={cn("flex gap-2 text-sm font-light", className)}>
      <Price value={rrp} className="text-gray-500 line-through" />
      <div className="flex items-center rounded-sm bg-red-500 px-1 text-[0.85em] font-medium text-white">
        -{reducedBy} %
      </div>
    </div>
  );
};
export default PriceRRP;
